import React from "react"
import { graphql } from "gatsby"
import { get } from "lodash"
import SiteWrapper from "../components/SiteWrapper/SiteWrapper"
import SiteLayout from "../components/SiteLayout/SiteLayout"
import TermsScreen, {
  StyledHeadingLargeText,
} from "../screens/TermsScreen/TermsScreen"
import { ITermsAndConditionsGraph } from "../data/cms/terms"
import PageLocation from "../analytics/components/PageLocation/PageLocation"
import { ROUTES } from "../navigation/routes"
import { getEnvKey } from "../utils/env"
import PageMetaTags, {
  CMSWebMetaSocial,
} from "../components/SiteMeta/PageMetaTags/PageMetaTags"

interface Data extends CMSWebMetaSocial {
  datoCmsWebTermsAndConditionsScreenDev?: ITermsAndConditionsGraph
  datoCmsWebTermsAndConditionsScreenStg?: ITermsAndConditionsGraph
  datoCmsWebTermsAndConditionsScreen?: ITermsAndConditionsGraph
  datoCmsWebTermsAndConditionsScreenPrd?: ITermsAndConditionsGraph
}

interface Props {
  data: Data
}

const TermsConditionsPage: React.FC<Props> = props => {
  const contentKey = getEnvKey({
    devKey: "datoCmsWebTermsAndConditionsScreenDev",
    stgKey: "datoCmsWebTermsAndConditionsScreenStg",
    uatKey: "datoCmsWebTermsAndConditionsScreen",
    prdKey: "datoCmsWebTermsAndConditionsScreenPrd",
    defaultKey: "datoCmsWebTermsAndConditionsScreen",
  })
  const data: ITermsAndConditionsGraph = get(props, `data.${contentKey}`)
  return (
    <SiteWrapper>
      <SiteLayout>
        <PageLocation pageName={ROUTES.terms.name}>
          <PageMetaTags data={props} />
          <TermsScreen
            heading={<StyledHeadingLargeText>T&C's</StyledHeadingLargeText>}
            content={data.content}
          />
        </PageLocation>
      </SiteLayout>
    </SiteWrapper>
  )
}

export default TermsConditionsPage

export const query = graphql`
  query TermsScreenQuery(
    $devEnv: Boolean = false
    $stgEnv: Boolean = false
    $uatEnv: Boolean = false
    $prdEnv: Boolean = false
  ) {
    datoCmsWebTermsAndConditionsScreenDev @include(if: $devEnv) {
      ...TermsAndConditionsDev
    }
    datoCmsWebTermsAndConditionsScreenStg @include(if: $stgEnv) {
      ...TermsAndConditionsStg
    }
    datoCmsWebTermsAndConditionsScreen @include(if: $uatEnv) {
      ...TermsAndConditions
    }
    datoCmsWebTermsAndConditionsScreenPrd @include(if: $prdEnv) {
      ...TermsAndConditionsPrd
    }
    datoCmsWebMetaSocialDev @include(if: $devEnv) {
      screenMetaTags: termsScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialStg @include(if: $stgEnv) {
      screenMetaTags: termsScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocial @include(if: $uatEnv) {
      screenMetaTags: termsScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialPrd @include(if: $prdEnv) {
      screenMetaTags: termsScreenMetaTags {
        ...PageMeta
      }
    }
  }
`
